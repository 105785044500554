import { ClockIcon, DocumentChartBarIcon, MapPinIcon, CheckCircleIcon } from '@heroicons/react/24/solid'

const features = [
  {
    name: 'Integrated Time Clock',
    description:
      'Staff can clock in and out directly from Shopify POS.',
    icon: ClockIcon,
  },
  {
    name: 'Realtime Reporting',
    description: 'Managers can easily track staff hours and generate reports from Shopify Admin.',
    icon: DocumentChartBarIcon,
  },
  {
    name: 'Multi-Location Support',
    description: 'Manage clock-ins and clock-outs for multiple locations.',
    icon: MapPinIcon,
  },
  {
    name: 'No Setup Required',
    description: 'Your staff can start tracking time immediately aftering installing the POS Time Tracker extension.',
    icon: CheckCircleIcon,
  },
]

export default function FeaturesSection() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32" id='features-section'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 sm:justify-items-center lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Plug and Play</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                POS Time Tracker is an easy to use time tracking app for your staff built into Shopify POS and Shopify Admin.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="/ipad.png"
            alt="Product screenshot"
            className="h-60 sm:h-72 object-cover lg:h-96 xl:h-[28rem]"
          />
        </div>
      </div>
    </div>
  )
}
