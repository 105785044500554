import type { LoaderFunctionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { useLoaderData } from '@remix-run/react';
import CallToActionSection from '~/components/callToActionSection';
import FaqSection from '~/components/faqSection';
import FeaturesSection from '~/components/featuresSection';
import FooterSection from '~/components/footerSection';
import HeroSection from '~/components/heroSection';
import IntercomWidget from '~/components/intercomWidget';
import PricingSection from '~/components/pricingSection';
import TestimonialsSection from '~/components/testimonialsSection';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);

  if (url.searchParams.get("shop")) {
    throw redirect(`/app?${url.searchParams.toString()}`);
  }

  return json({ loggedIn: false, production: process.env.NODE_ENV === "production" });
};

export default function App() {
  const loaderData = useLoaderData<typeof loader>();

  return (
    <>
      <HeroSection />
      <FeaturesSection />
      <TestimonialsSection />
      <CallToActionSection />
      <PricingSection />
      <FaqSection />
      <FooterSection />
      {
        loaderData.production ?
          <IntercomWidget plan={undefined} shop={undefined} user={undefined} intercomUserHash={undefined} staffCount={undefined} /> :
          null
      }
    </>
  )
}
