const faqs = [
  {
    id: 1,
    question: "Do I need a credit card to sign up?",
    answer:
      "No, you can install POS Time Tracker to your shopify shop without a credit card. You will only be charged at the end of your 7-day trial if you decide to continue using POS Time Tracker.",
  },
  {
    id: 2,
    question: "How do I add staff members once I install the app?",
    answer:
      "You don't! POS Time Tracker automatically syncs with your Shopify POS staff members and your staff members can start tracking their time right away.",
  },
  {
    id: 3,
    question: "What counts as a staff member?",
    answer:
      "We count a staff member towards your subscription if they have clocked in the past. If you have a staff member that has never clocked in, they will not be counted towards your subscription.",
  },

]

export default function FaqSection() {
  return (
    <div className="bg-white" id='FAQ'>
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
            <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
              starting a chat
            </a>{' '}
            and we’ll get back to you as soon as we can.
          </p>
        </div>
        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
